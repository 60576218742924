.pagination-container {
  display: flex;
  list-style-type: none;
  justify-content: center;
  margin-bottom: 50px;
}
.pagination-container .pagination-item {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
}
.arrowleft, .arrowRight{
  width: 50px;
  height: 50px;
  background-color: #fff;
  border: 1px solid rgba(51,51,51,.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 5px rgb(51 51 51 / 50%);
}
.arrowleft{
  margin-right: 50px;
}
.arrowRight{
  margin-left: 50px;
}
.arrowleft > img{
  height: 20px;
  width: 15px;
}
.arrowRight > img{
  height: 20px;
  width: 15px;
}
.pagination-container .pagination-item.disabled {
  pointer-events: none;
}
.pageNumber{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0 10px;
}
.pagination-container .pageNumber.selected {
      transform: scale(1.2);
}
.pagination-container .pagination-item.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}
.pagination-container .pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}

.cardOnMobile{
  position: relative;
  background-color: white!important;
  width: 400px;
  height: 110px;
}
@media only screen and (max-width: 600px) {
  .cardOnMobile{
    position: absolute;
    bottom: 0px;
    left: 0px;
    /* margin-bottom: -50px; */

  
  }
}