@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
  font-family: "Whyte Inktrap";
  src: url(../public/new-ui/fonts/WhyteInktrap-Super.woff);
}

.d-flex {
  display: flex;
}
.justify-content-between {
  justify-content: space-between;
}
* {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}
html {
  scroll-behavior: smooth;
}
body {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  position: relative;
  overflow-y: scroll;
  position: relative;
}
.map__popover {
  position: fixed !important;
  left: -16vw;
  @media screen and (max-width: 1119px) {
    left: -44vw;
    bottom: -30vh;
  }
  @media screen and (max-width: 450px) {
    width: 330px !important;
  }
}

.slick-prev:before,
.slick-next:before {
  font-size: 30px !important;
  color: #ffffff !important;
  opacity: 0.5 !important;
}

.slick-prev:hover {
  color: #c72424 !important;
}
@media screen and (max-width: 749.9px) {
  .slick-arrow {
    visibility: hidden;
  }
}

.slick-slider {
  position: relative !important;
  cursor: pointer;
}
.slick-dots {
  position: absolute;
  width: 100%;
  bottom: 105px;
}
.imageEl {
  flex: 0 0 25%;
  max-width: 25%;
  @media screen and (min-width: 749.9px) {
    &:nth-child(1n) {
      padding-left: 4.5px;
    }
    &:nth-child(2n) {
      padding-left: 4.5px;
    }
    &:nth-child(3n) {
      padding-left: 4.5px;
    }
  }

  @media screen and (max-width: 749px) {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 4.5px;
    //  background-color: red;
  }
}
.imageElDrawer {
  height: 50%;
  @media screen and (max-width: 767.9px) {
    flex: 0 0 50%;
    max-width: 50%;
    &:nth-child(2n) {
      padding-left: 3px;
    }
    &:nth-child(3n) {
      padding-top: 3px;
    }
    &:nth-child(4n) {
      padding-top: 3px;
    }
  }
}
.flex-wrap {
  flex-wrap: wrap;
}

.slick-dots {
  li {
    height: 12px !important;
    width: 12px !important;
    background-color: white;
    border: 1px solid rgba(51, 51, 51, 0.8);
    border-radius: 100%;
    transition: 0.3s all ease;
    margin-left: 9px !important;
    button {
      &::before {
        display: none !important;
      }
    }
  }
  .slick-active {
    background-color: #333;
  }
}
.chakra-accordion {
  padding: 0 22px;
}
.chakra-accordion__button {
  background-color: white !important;
  border: none !important;
  border-bottom-width: 0 !important;
  border-top-width: 0 !important;
  padding: 0 !important;
}
.chakra-accordion__panel {
  height: 100px !important;
  padding: 22px 0 0 36px !important;
  margin: 0 !important;
  margin-top: 15px !important;
  border-left: 1px solid rgba(51, 51, 51, 0.101961) !important;
  font-size: 16px !important;
  line-height: 24px !important;
  box-sizing: border-box !important;
  text-transform: uppercase !important;
}
.cursor--pointer {
  cursor: pointer;
}
.zora__header {
  background-color: #ffffff;
  position: relative;
}
.overflow {
  overflow: hidden;
}
.chakra-button {
  white-space: unset !important;
}

//
.btnClass {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(36, 31, 31, 0.582);
  height: 100%;
  width: 96%;
  color: #ffffff;
  display: block;
  font-weight: 500;
  font-size: 18px;
}
.btnClassMobileView {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(36, 31, 31, 0.582);
  height: 94%;
  width: 94%;
  color: #ffffff;
  display: block;
  font-weight: 500;
  font-size: 13px;
}

.mainImageGallery {
  width: 644px;
  // padding-right: 20px;
  // padding-left: 20px;
  position: relative;
  margin: auto;
}
.childImageGallery {
  padding: 4.5px;
  height: 300px;
  width: 50%;
}
.navbarContainer {
  // background-color: yellow;
  padding-left: 45px;
  padding-right: 45px;
}
.divContainer {
  // background-color: yellow;
  margin-left: 45px;
  margin-right: 45px;
}
@media screen and (min-width: 749.9px) and (max-width: 1150px) {
  .mainImageGallery {
    width: 544px;
    // background-color: gold;
  }
  .childImageGallery {
    padding: 6px;
    height: 250px;
    width: 50%;
  }
  .divContainer {
    // background-color: rgb(28, 230, 179);
    margin-left: 56px;
    margin-right: 56px;
  }
}
@media screen and (max-width: 750px) {
  .mainImageGallery {
    width: 370px;
    //  background-color: rgb(34, 81, 236);
  }
  .childImageGallery {
    //  padding-left: 12px;
    height: 163px;
    width: 50%;
  }
  .divContainer {
    // background-color: rgb(230, 28, 28);
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media screen and (max-width: 371px) {
  .mainImageGallery {
    width: 100%;
    //  padding-left: 10px;
    //  background-color: rgb(34, 81, 236);
  }
  .childImageGallery {
    //  padding-left: 12px;
    height: 163px;
    width: 50%;
  }
}

@media screen and (max-width: 1152px) {
  .silkCarasole {
    // background-color: rgb(167, 219, 24);
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    // background-color: gold;
  }
}
@media screen and (max-width: 1152px) {
  .silkCarasoleforSpecial {
    // background-color: rgb(167, 219, 24);
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    // padding-left: 20px;
    // padding-right: 20px;
    // background-color: gold;
  }
}
@media screen and (max-width: 749px) {
  .silkCarasoleforSpecial {
    // background-color: rgb(167, 219, 24);
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    // background-color: gold;
  }
  .displaynone {
    display: none;
  }
}
@media screen and (max-width: 749px) {
  .SpecialsilkCarasole {
    // background-color: rgb(167, 219, 24);
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    // background-color: gold;
  }
}
@media screen and (min-width: 749px) {
  .SpecialsilkCarasole {
    // background-color: rgb(167, 219, 24);
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    // padding-left: 20px;
    // padding-right: 20px;
    // background-color: gold;
  }
}
.improveThird {
  background-color: #ffffff !important;
  // margin-top: -20px;
  opacity: 0.8 !important;
  border: 1px solid #333333 !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  height: 33px !important;
}
.improveThird:hover {
  background-color: #ffffff !important;
}
.improveThirdP {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  height: 19px;
  line-height: 18px;
  text-align: center;

  color: #333333;
}
.closeMobileButton {
  height: 30px;
  width: 30px;
  // background-color: yellow;
}
.closeMobileImage {
  height: 32.5px;
  // background-color: yellow;
}

.grid-column-gap--lg {
  @media screen and (min-width: 1024px) {
    grid-column-gap: 8rem;
  }
}
:root {
  --brand-color: #8d6a20;
}
.drop-zone {
  width: 300px;
  height: 200px;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  transition: border-color 0.3s ease;
}

.drop-zone.dragging {
  border-color: #3b97d3;
}
.dropped-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
