@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Roboto:wght@400;500;700;900&display=swap");

.header-logo figure {
	width: 141px;
	height: 32.5px;
}

.container-flud {
	padding-left: 50px;
	padding-right: 50px;
}

.header-row.d-flex {
	align-items: center;
}

.header-logo {
	min-width: 210px;
}

.header-login-area {
	flex: 1;
}

.header-login-area ul {
	text-align: right;
}

.header-login-area h3 {
	text-align: right;

	font-style: normal;
	font-weight: 300;
	font-size: 15px;
	cursor: pointer;
	color: #333333;
}

.nav-link {
	font-family: Poppins;
	font-size: 18px;
	color: #6e6d7a;
}

.blog-header {
	padding: 15px 0;
}

.banner-img-wrp {
	width: 200px;
	height: 200px;
}

.banner-img-wrp {
	width: 200px;
	height: 200px;
}

.blog-banner {
	height: auto;
	background: #eef0f7;
}

.blog-container {
	max-width: 850px;
	margin: auto;
	padding: 80px 0;
}

.banner-content-blog h2 {
	font-style: normal;
	font-weight: bold;
	font-size: 30px;
	margin-bottom: 30px;
	font-family: "Merriweather", serif;
	color: #000000;
}

.em-id {
	width: 265px;
	height: 42px;
	border: 1px solid #a2a3a8;
	color: #a2a3a8;
	border-radius: 3px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	background: transparent;
	padding: 5px 10px;
	padding-right: 30px;
	font-size: 16px;
}

.tryit-btn {
	width: 366px;
	min-width: 101px;
	background: #8d6a20;
	border: 1px solid #8d6a20;
	box-sizing: border-box;
	border-radius: 5px;

	color: #fff;
	height: 42px;
	font-size: 16px;
	font-weight: 500;
	font-family: "Roboto", sans-serif;
	position: relative;
	right: 20px;
	cursor: pointer;
}

.try-it-row {
	display: flex;
	justify-content: center;
	margin-top: 50px;
}

.banner-content-blog p {
	line-height: 1.6;
	width: 100%;
	margin: auto;
}

.blog-banner-row.d-flex {
	justify-content: center;
}

.blog-main-wrp {
	padding: 50px 0;
}

.blog-item-heading span {
	font-style: normal;
	font-weight: bold;
	font-size: 27px;
	color: #333333;
	font-family: "Merriweather", serif;
}

.author-img-wrp figure {
	width: 35px;
	height: 35px;
	border-radius: 100%;
	overflow: hidden;
}

.author-bx-wrp {
	display: flex;
	align-items: center;
	margin: 30px 0;
}

.author-bx-info {
	margin-left: 15px;
}

.author-bx-info * {
	font-size: 16px;
	line-height: 24px;
	/* identical to box height */
	color: #333333;
}

.blog-item-dis p {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.5;
	color: #000000;
}

.blog-item-dis {
	margin-top: 30px;
}

.blog-item-img img {
	max-width: 100%;
}

.Continue-Reading {
	width: 327px;
	height: 47px;
	background: #8d6a20;
	border: 1px solid #8d6a20;
	box-sizing: border-box;
	border-radius: 5px;
	display: inline-block;
	text-align: center;
	line-height: 47px;
	font-weight: 500;
	font-size: 18px;
	color: #ffffff;
	/* font-family: 'Roboto', sans-serif; */
	padding: 0 15px;
	letter-spacing: 0.5px;
}
.read-more-btn {
	text-align: center;
	margin-top: 40px;
}

.blog-post-item-wrp {
	margin-bottom: 70px;
	border-bottom: 3px solid rgba(0, 0, 0, 0.1);
	padding-bottom: 70px;
}

.post-banner-wrp {
	background: #eef0f7;
	padding: 40px 50px 100px;
}

h1.post-title {
	text-align: center;
	font-style: normal;
	font-weight: bold;
	font-size: 30px;
	margin-bottom: 30px;
	font-family: "Merriweather", serif;
	color: #333333;
}

.post-page-header {
	margin-bottom: 40px;
}

.post-banner-wrp {
	margin-bottom: 40px;
}
h3.text-3xl.mb-3.leading-snug {
	font-style: normal;
	font-weight: bold;
	font-size: 27px;
	color: #333333;
	font-family: "Merriweather", serif;
}
h2.mb-8.text-6xl.md\:text-7xl.font-bold.tracking-tighter.leading-tight {
	font-size: 45px;
}

@media (max-width: 767px) {
	.container-flud {
		padding-left: 15px;
		padding-right: 15px;
	}
	.post-banner-wrp {
		padding: 20px 15px !important;
	}
	h1.post-title {
		text-align: left;
		font-size: 26px;
	}
	.banner-content-blog h2 {
		font-size: 26px;
	}
	.banner-content-blog p {
		font-size: 16px;
	}
	.blog-container {
		padding: 30px 0;
	}

	.blog-banner {
		padding: 0 15px;
	}

	.blog-main-wrp {
		padding: 40px 15px;
		overflow: hidden;
	}

	.tryit-btn {
		right: 4px;
	}
	.em-id {
		width: 100%;
		margin-left: 4px;
	}
	.header-logo {
		min-width: 160px;
	}
	.Continue-Reading {
		width: 290px;
		font-size: 16px;
	}
	.blog-post-item-wrp {
		margin-bottom: 30px;
		padding-bottom: 45px;
	}
	.author-bx-info * {
		font-size: 14px;
	}
	.blog-item-heading span,
	.markdown-styles_markdown__3ovrV h2 {
		font-size: 22px !important;
	}
	.markdown-styles_markdown__3ovrV p {
		font-size: 16px;
	}
}
