.Columns {
  margin: 0 auto;
  width: 50%;
  background-color: #ffffff;
}


.Col > * {
  margin: 20px 0;
}
ul > * {
  margin: 20px;
}
p {
  font-weight: 500;
}
.heading1 {
  font-size: 40px;
  font-weight: bold;
}
.heading2 {
  font-size: 20px;
  font-weight: bold;
}
ul{
  list-style: disc;
  padding-left: 40px !important;
}

@media only screen and (max-width: 800px) {
  .Columns {
    width: 100%;
    padding: 20px;
  }
}