.pills{
  display: flex;
  @media screen and (min-width: 1153px) {
      display: none;
  }
  .list{
    border: 1px solid #8d6a20;
    background-color: #8d6a20;
    color: #fff;
    margin-right: 6px;
    width: 52px;
    height: 35px;
    padding: 7px 0;
    text-align: center;
    box-sizing: border-box;
    border-radius: 4px;
    transition: .2s all;
    cursor: pointer;
    font-size: 14px;
    line-height: 21px;
  }
  .maps{
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    width: 52px;
    height: 35px;
    padding: 7px 0;
    text-align: center;
    box-sizing: border-box;
    border-radius: 4px;
    transition: .2s all;
    cursor: pointer;
    color: #757575;
    transition: .2s all;
    &:hover{
      color: #8d6a20;
    border: 1px solid #8d6a20;
    transition: .2s all;
    }
  }
}