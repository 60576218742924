.drawer {
	transform: translateX(150%);
	transition: 0.5s all ease-in-out;
	width: 100vw;
	min-height: 100vh;
	max-height: 100%;
	top: 0;
	position: fixed;
	left: 0;
	bottom: 0;
	background-color: white;
	z-index: 9;
	max-width: 100%;

	&.active {
		transform: translateX(0);
	}
	.data {
		width: 680px;
		margin: auto;
		@media screen and (min-width: 1152px) {
			width: 740px;
		}
		@media screen and (min-width: 749.9px) {
			padding-left: 56px !important;
			padding-right: 56px !important;
			// background-color: red;
		}
		@media screen and (max-width: 749.9px) {
			padding-left: 20px !important;
			padding-right: 20px !important;
			// background-color: rgb(30, 97, 243);
		}
		@media screen and (max-width: 752.9px) {
			width: 100%;
			max-width: 100%;
			// background-color: rgba(51, 51, 51, 0.101961);
			margin: 0;
		}
		@media screen and (max-width: 1199.9px) {
			min-height: calc(100vh - 113px);
		}
	}
	.flex {
		position: sticky;
		top: 0;
		width: 100%;
		display: flex;
		margin: auto;
		background-color: white;
		padding-top: 19px;
		padding-bottom: 6px;
		// background-color: gold;
	}
	.zIndex {
		z-index: 99;
	}
	.back {
		transition: 0.3s all ease;
		padding-left: 3px;
		padding-top: 13px;
		&:hover {
			opacity: 0.7;
		}
	}
	// .back-text{
	//   font-weight: 400;
	//   font-size: 14px;
	//   line-height: 21px;
	// }

	.image-gallery {
		// padding-left: 20px;
		// padding-right: 20px;
		display: flex;
		flex-direction: row;
	}
	.image-gallery--item {
		flex: 0 0 25%;
		// max-width: 25%;
		padding: 0 5px;
	}

	.service-pricing {
		display: flex;
		justify-content: space-between;
		padding: 25px 0;
		// background-color: black;
		margin-left: 15px;
		margin-right: 13px;
		border-bottom: 1px solid rgba(51, 51, 51, 0.101961);
	}
	.heading {
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
	}
	.time {
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
	}
	.priceType {
		font-size: 14px;
		line-height: 21px;
		font-weight: 400;
		opacity: 0.6;
		color: #333333;
	}
	.price {
		font-size: 17px;
		line-height: 25.5px;
		font-weight: 500;
	}
	.bottom {
		margin-top: 40px;
		margin-bottom: 100px;
		margin-right: 14px;
		margin-left: 15px;
	}
	.sticky-bottom {
		width: 100vw;
		height: 60px;
		max-width: 100%;
		padding: 13px 0;
		box-sizing: border-box;
		position: sticky;
		bottom: 0;
		left: 0;
		background-color: #fff;
		box-shadow: 0 -2px 5px rgb(0 0 0 / 25%);
		transition: 0.5s all;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.mapMobile {
	position: fixed;
	width: 100vw;
	height: 100vh;
	max-width: 100%;
	top: 0;
	left: 0;
	background-color: white;
	z-index: 9;
	padding-top: 60px;
	transform: translateX(100%);
	transition: 0.5s all ease;
	&.active {
		transform: translateX(0);
	}
	.close {
		height: 40px;
		width: 40px;
		position: absolute;
		border-radius: 13px;
		top: 75px;
		left: 30px;
		cursor: pointer;
		background-color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.header-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: 0.4s all;
	z-index: 120;
	transform: translateY(-120%);
	&.active {
		transform: translateY(0) !important;
	}
}
.header-menu {
	position: relative;
	z-index: 99;
	background-color: #f5ebe8;
	height: 100vh;
	width: 100%;
}

.header-menu-upper-box {
	display: flex;
	padding: 16px 12px;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid rgba(51, 51, 51, 0.101961);
}
.header-overlay {
	background-color: rgba(51, 51, 51, 0.701961);
	width: 100%;
	height: 100vh;
	-webkit-transition: 0.4s all;
	-moz-transition: 0.4s all;
	transition: 0.4s all;
	position: fixed;
	left: 0;
	top: 0;
}
