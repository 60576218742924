.main {
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
}
.text {
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  height: 8%;
  margin-top: 23px;
  line-height: 21px;
  /* background-color: green; */
}
.close {
  position: absolute;
  width: 90px;
  left: 20%;
  top: 25px;
  display: flex;
  flex-direction: row;
}

.close:hover {
  opacity: 0.7;

}
.closeBtnImage {
  background-image: url("../components/assets/close.png");
  background-size: cover;
  height: 13px;
  width: 13px;
  padding-left: 10px;
}

.btnText {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: white;
  padding-left: 7px !important;
  margin-top: -5px !important;
  font-weight: 600;
}

.imgDiv {
  height: 92%;
  width: 100%;
}
.setting{
  position: relative;
  margin: auto;
  height: 450px;
  width: 600px;
  display: flex;
  justify-content: space-evenly;

}
.mainImg {
   height: 450px;
   width: 450px;  
 }
.circleBorderLeft {
  position: relative;
  top: 40%;
  width: 40px;
  height: 40px;
  border: 1px solid white;
  border-radius: 50%; 
  cursor: pointer;
}
.btnLeft {
  background-image: url("../components/assets/Vector\ 20\ \(2\).png");
  background-size: cover;
  width: 10px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.btnLeft:hover {
  opacity: 1;
}
.circleBorderRight {
  position: relative;
  top: 40%;
  right: 0;
  width: 40px;
  height: 40px;
  border: 1px solid white;
  border-radius: 50%; 
  cursor: pointer;
}
.btnRigth {
  background-image: url("../components/assets/Vector\ 20\ \(3\).png");
  background-size: cover;
  width: 12px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  
}
.btnRigth:hover {
  opacity: 1;
}


 @media only screen and (max-width: 600px) {
  .setting{
    position: relative;
    margin: auto;
    height: 320px;
    width: 320px; 
  }
  .mainImg {
    height: 100%;
    width: 100%;  
  }
  .circleBorderLeft {
    position: absolute;
    top: 40%;
    width: 40px;
    height: 40px;
    left: 10px;
    border: 1px solid white;
    border-radius: 50%; 
    cursor: pointer;
    background-color: #FFFFFF;
    opacity: 0.5;
  }
  .btnLeft {
    background-image: url("../components/assets/before.png");  
    width: 10px;
    height: 15px;

  }
  .circleBorderRight {
    position: absolute;
    top: 40%;
    right: 10px;
    width: 40px;
    height: 40px;
    border: 1px solid white;
    border-radius: 50%; 
    cursor: pointer;
    background-color: #FFFFFF;
    opacity: 0.5;
  }
  .btnRigth {
    background-image: url("../components/assets/next.png");  
    width: 10px;
    height: 15px;

  }
  .close {
    left: 5%;
   
  }

}

.mainImgChild {
  height: 100%;
  width: 100%;
}


.descriptionDiv {
  position: relative;
  margin: auto;
  width: 450px;
  color: white;
  padding-top: 10px;
  max-height: 40%;
  overflow-y: auto;
}
