/* Avatar Section */
.avatar-Images-Descritpion-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.avatarAndTextRow {
    display: inline-flex;
    cursor: pointer;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: sticky;
    z-index: 1;
    top: 0px;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
}
.cityNameAndStreetAddress{
    font-size:14px;
    line-height:18px;
    font-weight:400;
    color:#333333;
    opacity:0.5;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; 
    width: 300px;
}
.crossImageContainer {
    cursor: pointer;
    position: relative;
    left: 75px;
    top: 8px;
}

/* Images Section */
.imagesContainer {
    display: inline-flex;
    width: 100%,
}

.bigHeightImg {
    width: 220px;
    height: 220px;
    border-radius: 3px;
    margin-right: 14px;
}

.smallHeightImg {
    width: 105px;
    height: 105px;
    margin-right: 8px;
    margin-bottom: 8px;
}

.fourImagesContainer {
    display: inline-flex;
}

.imgAndSeAllBtnContainer {
    width: 105px;
}

.lowOpacityBtnContainer {
    height: 105px;
    width: 105px;
    background-color: rgba(36, 31, 31, 0.582);
    position: absolute;
    top: 267px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.seeAllBtn {
    font-size: 16px;
    font-weight: 500;
}

/* Title And Descritpion Section */
.titleAndDescriptionContainer {
    margin-top: 23px;
}

.scrollableContent {
    height: 230px;
    overflow: scroll;
    margin-top: 10px;
}

.title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
}

.lessDescription {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 13px;
    width: 90% !important;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: #333333;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fullDescription {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 13px;
    width: 90% !important;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: #333333;
}

.readMoreBtnContainer {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.readMore, .closeBtn {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
}

.arrowIcon {
    width: 20px;
    height: 15px;
    margin-top: 10px;
}

.readLessBtnContainer {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 70px;

}

/* Table Section */
.tableContainer {
    margin-bottom: 100px;
}

.tableRow {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 22px;
}
.nameAndTimeContainer{
    width: 75%;
}
.priceContainer{
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.blackBoldText {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
}

.lightBlackText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    opacity: 0.6;
}
.lineThroughPrice{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #333333;
    opacity: 0.6;
    text-decoration: line-through;
}
/* Bottom Button Section */
.stickyBottom {
    position: fixed;
    height: 60px;
    bottom: 0;
    box-sizing: border-box;
    transition: 0.5s all;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff !important;
    width: 100%;
}

.divider {
    position: fixed;
    bottom: 60px;
}

.bottomBtn {
    width: 194px;
    height: 40px;
    background-color: #8D6A20;
    border-radius: 50px;
    border: 1px solid #8D6A20;
    color: white;
    margin-bottom: 8px;
}

/* Image Grid  */
.imgGridContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.imgRow {
    flex-wrap: wrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}

.gridImageRight {
    margin-bottom: 12px;
    width: 255px;
    height: 255px;
}

.gridImageLeft {
    margin-bottom: 12px;
    width: 255px;
    height: 255px;
    margin-right: 12px;
}
.gridImage{
    height: 100%;
    width: 100%;
}
/* Image Viewer */
.mainContainer {
    width: 100%;
    min-height: 100%;
    min-height: 100vh;
    background-color: black;
}

.centerContainer {
    display: flex;
    flex-direction: column;
}

.indexNumberAndBtnRow {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.indexNumber {
    color: white;
    font-size: 14px;
    font-weight: 400;
    height: 8%;
    line-height: 21px;
}

.closeBtnImage {
    background-image: url("../components/assets/close.png");
    background-size: cover;
    height: 13px;
    width: 13px;
    padding-left: 10px;
    position: relative;
    left: 200px;
    cursor: pointer;
}

.closeBtnImage:hover {
    opacity: 0.7;
}

.carouselContainer {
    margin-top: 30px;
}

.ImageAndArrowsRow {
    position: relative;
    margin: auto;
    height: 450px;
    display: flex;
    justify-content: space-evenly;
}

.leftBtnContainer {
    position: relative;
    top: 40%;
    width: 40px;
    height: 40px;
    border: 1px solid white;
    border-radius: 50%;
    cursor: pointer;
}

.leftBtn {
    background-image: url("../components/assets/Vector\ 20\ \(2\).png");
    background-size: cover;
    width: 10px;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

.carouselImg {
    height: 450px;
    width: 450px;
}

.rightBtnContainer {
    position: relative;
    top: 40%;
    right: 0;
    width: 40px;
    height: 40px;
    border: 1px solid white;
    border-radius: 50%;
    cursor: pointer;
}

.rightBtn {
    background-image: url("../components/assets/Vector\ 20\ \(3\).png");
    background-size: cover;
    width: 12px;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

.dotsRow {
    display: inline-flex;
    margin-top: 30px;
    align-items: center !important;
    justify-content: center !important;
    width: 100%;
    margin-bottom: 50px;
}

.outlinedDot {
    width: 4px;
    height: 4px;
    border-radius: 10px;
    border: 1px solid white;
    margin-left: 4px;
}

.coloredDot {
    width: 12px;
    height: 12px;
    border-radius: 10px;
    margin-left: 4px;
    background-color: white;
}

/* Media Query  */
@media screen and (max-width: 768px) {

    /* Service Page Images */
    .bigHeightImg {
        width: 185px;
        height: 185px;
        margin-right: 9px;
    }

    .smallHeightImg {
        width: 89px;
        height: 89px;
        margin-right: 6px;
        margin-bottom: 7px;
    }

    .imgAndSeAllBtnContainer {
        width: 89px;
    }

    .lowOpacityBtnContainer {
        height: 89px;
        width: 89px;
        top: 178px;
    }

    /* Grid Image */
    .gridImageRight {
        margin-bottom: 16px;
        height: 220px;
        width: 220px;
    }

    .gridImageLeft {
        margin-bottom: 16px;
        height: 220px;
        width: 220px;
        margin-right: 12px;
    }

    .crossImageContainer {
        left: 41px;
    }
}

@media screen and (max-width: 620px) {

    /* Image Carousel Page */
    .carouselImg {
        width: 320px;
        height: 320px;
    }

    .ImageAndArrowsRow {
        height: 320px;
    }

    .closeBtnImage {
        left: 140px;
    }
}

@media screen and (max-width: 500px) {

    /* Grid Images Page */
    .gridImageRight {
        margin-bottom: 9px;
        width: 48%;
        height: 10%;
    }

    .gridImageLeft {
        margin-bottom: 9px;
        width: 48%;
        height: 10%;
        margin-right: 9px;
    }

    .crossImageContainer {
        left: 8%;
    }
}

@media screen and (max-width: 468px) {
    /* Avatar */
.cityNameAndStreetAddress{
    font-size: 12px;
    width: 250px;
}
    /* Carousel Images Page */
    .carouselImg {
        width: 270px;
        height: 270px;
    }

    .ImageAndArrowsRow {
        height: 270px;
    }

    .closeBtnImage {
        left: 110px;
    }

    /* Grid Images Page */
    .crossImageContainer {
        left: 8%;
    }

    /* Service Details Page Description */
    .lessDescription, .fullDescription {
        width: 90% !important;
    }
}

@media screen and (max-width: 410px) {

    /* Service Details Page Description */
    .bigHeightImg {
        width: 163px;
        height: 165px;
        margin-right: 8px;
    }

    .smallHeightImg {
        width: 79px;
        height: 79px;
        margin-right: 7px;
        margin-bottom: 7px;
    }

    .imgAndSeAllBtnContainer {
        width: 79px;
    }

    .lowOpacityBtnContainer {
        height: 79px;
        width: 79px;
        top: 168px;
    }

    .crossImageContainer {
        left: 5%;
    }
}

@media screen and (max-width: 375px) {
    /* Carousel Images Page */
    .carouselImg {
        width: 320px;
        height: 320px;
    }

    .ImageAndArrowsRow {
        height: 320px;
    }

    .closeBtnImage {
        left: 43%;
    }

    /* Service Details Main Page */
    .stickyBottom {
        height: 55px;
    }

    .bottomBtn {
        width: 214px;
        height: 40px;
        margin-bottom: 2px;
    }

    .divider {
        bottom: 55px;
    }

    .bigHeightImg {
        width: 50%;
        border-radius: 3px;
        margin-right: 6px;
        height: 165px;
    }

    .smallHeightImg {
        width: 95%;
        height: 80px;
        margin-bottom: 5px;
        margin-right: 6px;
    }

    .fourImagesContainer {
        display: inline-flex;
        width: 50%;
    }

    .imgAndSeAllBtnContainer {
        width: 100%;
    }

    .lowOpacityBtnContainer {
        width: 20%;
        height: 80px;
        background-color: rgba(36, 31, 31, 0.582);
        position: absolute;
        top: 167px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lessDescription {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 13px;
        width: 90% !important;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        color: #333333;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .fullDescription {
        width: 90% !important;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 13px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        color: #333333;
    }

    /* Image Grid Page */
    .crossImageContainer {
        left: 2%;
    }
}

@media screen and (max-width: 360px) {
    /* Avatar */
    .cityNameAndStreetAddress{
        width: 200px;
    }
}